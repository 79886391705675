import httpClient from '../auth/interceptor';

const API_URL = '/answers';

class AnswerService {
    getAll() {
        return httpClient.get(API_URL);
    }

    getById(id) {
        return httpClient.get(`${API_URL}/${id}`);
    }

    getByOrganization(createdBy){
        return httpClient.get(`${API_URL}/organizations/${createdBy}`);
    }
    
    save(organizationId,body){
        return httpClient.post(`${API_URL}/organizations/${organizationId}`, body);
    }
}

export default new AnswerService();
