import httpClient from '../auth/interceptor';
const API_URL = '/questions';

class QuestionService {
    getAll() {
        return httpClient.get(API_URL);
    }

    getById(id) {
        return httpClient.get(`${API_URL}/${id}`);
    }

    create(organization) {
        return httpClient.post(API_URL, organization);
    }

    update(id,organization) {
        return httpClient.put(`${API_URL}/${id}`, organization);
    }    

    delete(id) {
        return httpClient.delete(`${API_URL}/${id}`);
    }        
}

export default new QuestionService();
