<template>
  <div id="legacy">
    <loading
      :active="perguntas.length <= 0 && !organization"
      :is-full-page="true"
    ></loading>
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <seall-header></seall-header>
    <main class="main-legacy">
      <carousel-cards></carousel-cards>

      <div class="page-legacy-legacy">

        <div class="div-legacy-legacy">
          <div
            style="
              background-color: #ffffff;
              text-align: center;
              display: flex;
              flex-direction: column;
            "
          >
            <h2></h2>

            <p>{{ $t("legacy.subtitle") }}</p>
          </div>

          <div class="div-legacy-questions">
            <span>{{ $t("legacy.info") }}</span>
            <div class="div-legacy">
              <div class="div-legacy-numbers">
                <i
                  class="cursor-pointer material-icons align-self-center"
                  v-tooltip="$t('legacy.tooltip')"
                >
                help_outline
                </i>                
                <div
                  v-for="(pergunta, index) in perguntas"
                  v-bind:key="pergunta"
                >
                  <svg
                    width="40"
                    height="40"
                    v-on:click.prevent="toggle_question(index)"
                  >
                    <circle
                      v-if="empty_question(index)"
                      key="question_empty"
                      cx="20"
                      cy="20"
                      r="20"
                      fill="#FFFFFF"
                      stroke="#E6E9ED"
                      stroke-width="2"
                    />
                    <circle
                      v-if="actual_question(index)"
                      key="question_now"
                      cx="20"
                      cy="20"
                      r="20"
                      fill="#533A8B"
                    />
                    <circle
                      v-if="respondido_question(index)"
                      key="question_respondido"
                      cx="20"
                      cy="20"
                      r="20"
                      fill="#00B7BB"
                    />
                    <text
                      v-if="empty_question(index)"
                      key="question_empty"
                      fill="#533A8B"
                      font-size="15"
                      font-family="Nunito-Regular"
                      x="50%"
                      y="50%"
                      dominant-baseline="middle"
                      text-anchor="middle"
                    >
                      {{ index + 1 }}
                    </text>
                    <text
                      v-else
                      fill="#ffffff"
                      font-size="15"
                      font-family="Nunito-Regular"
                      x="50%"
                      y="50%"
                      dominant-baseline="middle"
                      text-anchor="middle"
                    >
                      {{ index + 1 }}
                    </text>
                  </svg>
                </div>
              </div>
              <div
                class="div-perguntas"
                v-for="(pergunta, index) in perguntas"
                v-bind:key="pergunta"
              >
                <div v-if="actual_question(index)" key="question1_now">
                  <p class="p-5">{{ $t("legacy.questions.question" + index) }}</p>
                  <div class="div-nota">
                    <span>{{ $t("legacy.scale.bad") }}</span>

                    <div div class="div-radio-geral">
                      <div
                        class="div-radio"
                        v-for="opcao in opcoes"
                        v-bind:key="opcao"
                      >
                        <input
                          v-if="checked(index, opcao)"
                          key="checked"
                          class="input-radio"
                          :label="opcao"
                          type="radio"
                          id="1"
                          name="nota"
                          :value="opcao"
                          v-on:click="select_value(index, opcao)"
                        />
                        <input
                          v-else
                          class="input-radio"
                          :label="opcao"
                          type="radio"
                          id="1"
                          name="nota"
                          :value="opcao"
                          checked
                        />
                      </div>
                    </div>

                    <span>{{ $t("legacy.scale.good") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>

    <footer class="footer-legacy">
      <img src="./../images/barra_ods.svg" alt="Barra" style="width: 100%" />

      <div id="openModal" class="modalDialog">
        <div>
          <img src="./../images/card-icon1.svg" alt style="width: 10vw" />
          <h2>{{ $t("legacy.modal.title") }}</h2>
          <h3>
            <template v-if="!organization.hasOrganizationMais">
              {{ $t("legacy.modal.subtitle") }}
            </template>
            <template v-if="organization.hasOrganizationMais">
              {{ $t("legacy.modal.maissubtitle") }}
            </template>
          </h3>
          <p>{{ $t("legacy.modal.info") }}</p>

          <div class="div-nota-footer">
            <span>{{ $t("legacy.modal.scale.bad") }}</span>
            <div class="div-radio-footer">
              <input
                class="input-radio-footer"
                label="1"
                type="radio"
                id="1"
                name="1"
                value="1"
                v-model="org_note"
              />
              <input
                class="input-radio-footer"
                label="2"
                type="radio"
                id="2"
                name="1"
                value="2"
                v-model="org_note"
              />
              <input
                class="input-radio-footer"
                label="3"
                type="radio"
                id="3"
                name="1"
                value="3"
                v-model="org_note"
              />
              <input
                class="input-radio-footer"
                label="4"
                type="radio"
                id="4"
                name="1"
                value="4"
                v-model="org_note"
              />
              <input
                class="input-radio-footer"
                label="5"
                type="radio"
                id="5"
                name="1"
                value="5"
                v-model="org_note"
              />
            </div>
            <span>{{ $t("legacy.modal.scale.good") }}</span>
          </div>

          <div class="modalDialog-btns">
            <template v-if="!organization.hasOrganizationMais">
              <a href="#close" title="Close" class="close">
                <button type="button" class="legacy-modal-button-back">
                  {{ $t("legacy.modal.button_back") }}
                </button>
              </a>
              <button
                type="button"
                class="legacy-modal-button-continue"
                v-on:click.prevent="saveAnswer()"
              >
                {{ $t("legacy.modal.button_go") }}
              </button>
            </template>

            <template v-if="organization.hasOrganizationMais">
              <button
                type="button"
                class="legacy-modal-button-back"
                v-on:click.prevent="saveAnswer()"
              >
                {{ $t("legacy.modal.button_go_radar") }}
              </button>
              <button
                v-on:click.prevent="saveAnswer('https://www.seall.com.br/MAIS')"
                type="button"
                class="legacy-modal-button-continue"
              >
                {{ $t("legacy.modal.button_go_mais") }}
              </button>
            </template>
          </div>
        </div>
      </div>

      <div class="div-legacy-footer-button">
        <powered-by></powered-by>
        <a href="#openModal" v-if="!respondido">
          <button type="button" class="legacy-footer-button" :disabled="finish">
            {{ $t("legacy.button_bottom") }} &#10132;
          </button>
        </a>
        <template v-else>
          <button
            v-if="!organization.hasOrganizationMais"
            type="button"
            v-on:click.prevent="saveAnswer()"
            class="legacy-footer-button"
          >
            {{ $t("legacy.button_bottom") }} &#10132;
          </button>
          <button
            v-on:click.prevent="saveAnswer('https://www.seall.com.br/MAIS')"
            type="button"
            class="legacy-modal-button-continue"
          >
            {{ $t("legacy.modal.button_go_mais") }}
          </button>
        </template>
      </div>
    </footer>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import QuestionService from "../../services/questions/question.service.js";
import AnswerService from "../../services/answer/answer.service";
import AuthService from "../../services/auth/auth";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SeallHeader from "./Header.vue";
import PoweredBy from "./PoweredBy.vue";
import CarouselCards from "./CarouselCards.vue";
export default {
  name: "legacy",
  data() {
    return {
      valor: 4.5,
      perguntas: [],
      opcoes: "",
      respostas: [],
      question: 0,
      org_note: 0,
      finish: true,
      respodindo: false,
      question_now: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      question_empty: [
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      question_respondido: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
      organization: { hasOrganizationMais: false },
    };
  },
  mounted() {
    this.opcoes = [1, 2, 3, 4, 5];
    this.loading = true;

    this.organization = JSON.parse(localStorage.getItem("organization"), true);

    if (AuthService.validateOrganizationNotExists()) {
      this.$notify({
        title: this.$t("main.fill_organization"),
        type: "error",
      });
      setTimeout(() => {
        window.location = "/organization";
      }, 2000);
    }

    const cogId = AuthService.getCogId();

    QuestionService.getAll().then((questions) => {
      this.perguntas = questions.data;
    });

    AnswerService.getByOrganization(cogId).then((answers) => {
      answers.data.forEach((awnser, index) => {
        this.respostas.push(awnser.note);
        this.question_respondido[index] = awnser.note;
      });

      this.respondido =
        this.question_respondido.filter((q) => q).length ===
        this.question_respondido.length;
    });

    if (window.screen.availWidth < 1025) this.valor = 1.25;
    else this.valor = 4.5;
  },
  methods: {
    actual_question(index) {
      return this.question_now[index];
    },
    empty_question(index) {
      return this.question_empty[index];
    },
    respondido_question(index) {
      return this.question_respondido[index];
    },
    checked(index, opcao) {
      if (this.respostas[index] == opcao) {
        return false;
      } else {
        return true;
      }
    },
    select_value(index, opcao) {
      this.respostas[index] = opcao;

      if (this.respostas.length == 17) this.finish = false;

      if(index != 16)
        this.toggle_question(index+1);
    },
    toggle_question(value) {
      if (value != this.question) {
        if (this.respostas[this.question]) {
          this.question_respondido[this.question] = true;
          this.question_empty[this.question] = false;
        } else {
          this.question_respondido[this.question] = false;
          this.question_empty[this.question] = true;
        }

        this.question_now[value] = true;
        this.question_empty[value] = false;
        this.question_respondido[value] = false;
        this.question_now[this.question] = false;

        this.question = value;
      }

      this.respondido =
        this.question_respondido.filter((q) => q).length ===
        this.question_respondido.length;
    },
    saveAnswer(redirect = "/map") {
      const body = [];
      const org = JSON.parse(localStorage.getItem("organization"));
      if (!org.id) {
        localStorage.removeItem("organization");
        return;
      }
      this.respostas.forEach((item, i) => {
        body.push({
          question: this.perguntas[i],
          note: item,
          organization: org,
        });
      });
      AnswerService.save(org.id, body).then(() => {
        window.location = redirect;
      });
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Loading,
    SeallHeader,
    PoweredBy,
    CarouselCards,
  },
};
</script>

<style scoped>
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.header-legacy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
}

.div-legacy-header-links {
  text-decoration: none;
}

.legacy-header-link {
  color: #a3a3a3;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  padding: 0vh 2vw;
  text-decoration: none;
}

.legacy-header-button {
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 1vh 3vw;
}

.material-icons {
  color: #000000;
}

.div-legacy-header-photo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}

.div-legacy-header-photo i {
  padding: 0vh 1vw 0vh 3vw;
}

.div-legacy-header-photo img {
  border-radius: 100%;
  overflow: hidden;
  height: 10%;
  width: 10%;
}

.main-legacy {
  background-color: #ffffff;
}

.div-legacy-cards {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2vh 0vw 2vh 1vw;
}

.legacy-card {
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  max-height: 80px;
  margin: 0vh 1vw;
  padding: 5vh 1vw;
  width: 25vw;
}

.legacy-card p {
  color: #687d9b;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  padding: 0vh 0.5vw;
}

.legacy-card img {
  width: 4vw;
}

.page-legacy-legacy {
  display: flex;
  flex-direction: row;
}

.div-legacy-legacy {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 5vh 0vw 0vh;
  margin: 0vh 0vw;
  width: 100vw;
}

.div-legacy-legacy h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 133%;
  text-align: center;
  color: #533a8b;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.div-legacy-legacy p {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  text-align: center;
  color: #a3a3a3;
  padding: 1vh 0vw 5vh;
  margin: 0vh 0vw;
  width: 60vw;
  text-align: center;
}

.div-legacy-questions {
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  width: 100%;
  padding: 0vh 0vw 5vh;
  margin: 0vh 0vw;
}

.div-legacy-questions span {
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.div-legacy {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 3vh 5vw;
  background: #ffffff;
  box-shadow: 0px 2px 1px -1px rgba(112, 119, 128, 0.2),
    0px 1px 1px rgba(112, 119, 128, 0.14), 0px 1px 3px rgba(112, 119, 128, 0.12);
  border-radius: 14px;
  text-align: center;
  height: 40vh;
  width: 70vw;
}

.div-legacy-numbers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.div-legacy-numbers svg {
  cursor: pointer;
}

.div-legacy-legacy span {
  color: #000000;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vh;
  line-height: 130%;
  text-align: center;
  opacity: 0.4;
  padding: 5vh 0vw;
}

.div-legacy p {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vh;
  line-height: 125%;
  text-align: center;
  color: #3f3f3e;

  height: 15vh;
  width: 70vw;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.div-nota {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1vh 0vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5vh;
}

.div-nota span {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  color: #9ea4ac;
  padding: 0vh 3vw;
}

.div-radio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.div-radio-geral {
  display: flex;
  flex-direction: row;
}

.div-radio .input-radio {
  appearance: none;
  cursor: pointer;
  border: 1px solid #9d9d9c;
  margin: 0vh 0vw;
  padding: 1vh 2vh;
  background: #f5f5f5;
  color: #9d9d9c;
  font-family: Nunito-Regular;
  font-size: 2.5vh;
  font-family: NunitoSans-Regular;
  transition: all 200ms linear;
}

.div-radio .input-radio:checked {
  background: #492e85;
  color: #fff;
  border: 1px solid #533a8b;
  font-size: 2.5vh;
  padding: 1vh 2vh;
}

.div-radio .input-radio:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}

.div-legacy-half_circle2 {
  padding: 70vh 0vw 0vh;
}

.footer-legacy {
  height: 0vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.footer-legacy svg {
  width: 5%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw 2vh;
}

.footer-legacy rect {
  width: 100%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.div-legacy-footer-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0vh 20vw;
}

.legacy-footer-button {
  color: #ffffff;
  background-color: #2f1764;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 5vh 0vw;
  padding: 1vh 3vw;
}

.legacy-footer-button:disabled {
  color: #ffffff;
  background-color: #9ea4ac;
  border-color: #9ea4ac;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 5vh 0vw;
  padding: 1vh 3vw;
}

.modalDialog {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}

.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}

.modalDialog > div {
  width: 40vw;
  margin: 10vh 30vw;
  position: relative;
  padding: 1vh 3vw;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);
}

.modalDialog img {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.modalDialog h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 133%;
  text-align: center;
  color: #533a8b;
  margin: 0vh 0vw;
  padding-bottom: 5vh;
}

.modalDialog h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: #9d9d9c;
  margin: 0vh 0vw;
  padding-bottom: 2vh;
}

.modalDialog p {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  text-align: center;
  color: #9d9d9c;
  margin: 0vh 0vw;
  padding-bottom: 0vh;
}

.modalDialog-btns {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 5vh 0vw 0vh 0vw;
}

.legacy-modal-button-back {
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 8px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 1vw;
  padding: 1vh 2vw;
}

.legacy-modal-button-continue {
  color: #ffffff;
  background-color: #2f1764ff;
  border-color: #2f1764;
  border-radius: 8px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 1vh 2vw;
}

.div-nota-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 0vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10vh;
}

.div-nota-footer span {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
  color: #9ea4ac;
}

.div-radio-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0vh 0vw;
}

.div-radio-footer .input-radio-footer {
  appearance: none;
  cursor: pointer;
  border: 1px solid #9d9d9c;
  margin: 0vh 0vw;
  padding: 1vh 2vh;
  background: #f5f5f5;
  color: #9d9d9c;
  font-family: Nunito-Regular;
  font-size: 2.5vh;
  font-family: NunitoSans-Regular;
  transition: all 200ms linear;
}

.div-radio-footer .input-radio-footer:checked {
  background: #492e85;
  color: #fff;
  border: 1px solid #533a8b;
  font-size: 2.5vh;
  padding: 1vh 2vh;
}

.div-radio-footer .input-radio-footer:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}

.div-perguntas p {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
}

@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 10%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-legacy {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .div-legacy-header-links {
    text-decoration: none;
    padding: 3vh 0vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .div-legacy-header-photo {
    justify-content: center;
    width: 100%;
  }

  .div-legacy-header-photo img {
    border-radius: 100%;
    overflow: hidden;
    height: 10%;
    width: 10%;
  }

  .div-legacy-header-img img {
    width: 40vw;
  }

  .legacy-card {
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    height: 10vh;
    margin: 0vh 0vw;
    padding: 5vh 3vw;
    width: 75vw;
  }

  .legacy-card img {
    width: 10vw;
  }

  .div-legacy-legacy p {
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    color: #a3a3a3;
    padding: 1vh 0vw 5vh;
    margin: 0vh 0vw;
    width: 100vw;
    text-align: center;
  }

  .div-legacy {
    padding: 5vh 3vw;
    height: 80vh;
    width: 75vw;
    justify-content: flex-start;
  }

  .div-legacy-numbers {
    justify-content: center;
    height: 20vh;
  }

  .div-legacy-numbers svg {
    cursor: pointer;
  }

  .div-legacy-legacy span {
    color: #000000;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5vh;
    line-height: 130%;
    text-align: center;
    opacity: 0.4;
    padding: 5vh 0vw;
  }

  .div-legacy p {
    color: #000000;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: 300;
    font-size: 3vh;
    line-height: 120%;
    text-align: center;
    height: 40vh;
    width: 75vw;
    padding: 3vh 0vw;
    margin: 0vh 0vw;
  }

  .div-nota {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0vh 3vw;
    height: 5vh;
  }

  .div-nota span {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
  }

  .div-radio-geral {
    display: flex;
    flex-direction: row;
    padding: 1vh 0vw;
  }

  .modalDialog > div {
    width: 90vw;
    margin: 10vh 2.5vw;
  }
}
</style>